import React from 'react';

const labelsKo = {
  seo: {
    title: '스마트 디바이스 전용 플랫폼 | Uplink+',
    description: 'IoT 스마트 디바이스 전용 플랫폼',
    keywords: [
      '업링크+',
      '업링크 플러스',
      '스마트 디바이스',
      '플랫폼',
      'Uplink+',
      'UplinkPlus',
      'Smart Device Platform',
      'IoT',
      'IoT Platform',
    ],
  },
  navBar: {
    signInLabel: '로그인',
    items: [
      {
        label: '홈',
        path: '#banner_section',
        offset: '70',
      },
      {
        label: '서비스',
        path: '#service_section',
        offset: '70',
      },
      // {
      //   label: '가격',
      //   path: '#pricing_section',
      //   offset: '70',
      // },
      {
        label: '파트너',
        path: '#partner_section',
        offset: '70',
      },
    ],
  },
  banner: {
    freeTrial: 'DEMO 신청',
    heading: 'UPGRADE TO SMART DEVICE',
    description:
      'UPLINK+는 강력한 스마트 디바이스 전용 플랫폼을 제공하여 제품의 수준을 한단계 끌어올려 드립니다. 또한 UPLINK+ IoT 모듈(와이파이)을 사용하면 플랫폼 연결 작업이 필요없어 개발시간을 단축, 쉽고 빠르게 스마트 디바이스로 업그레이드할 수 있습니다.',
  },
  serviceFlow: {
    subheading: 'UPLINK+ 서비스 개요',
    heading: 'UPLINK+ IoT 플랫폼 서비스 개요',
    items: [
      {
        id: 1,
        icon: 'flaticon-atom',
        title: 'Transform IoT',
        desc: '귀사의 디바이스를 완벽한 IoT 스마트 디바이스로 만들어 드립니다.',
      },
      {
        id: 2,
        icon: 'flaticon-prototype',
        title: '모니터링 및 분석',
        desc:
          '다양한 IoT환경을 고려하여 대용량 센싱 데이터를 실시간으로 수집/관리 하여 분석해 드립니다.',
      },
      {
        id: 3,
        icon: 'flaticon-startup',
        title: '자동화',
        desc:
          '한층 업그레이드된 보안 기능과 내 생각대로 만들어 가는 신개념 IoT 자동화 기능을 제공합니다.',
      },
    ],
  },
  concept: {
    freeTrial: 'DEMO 신청',
    heading: '전문 개발자가 필요 없는 올인원 IoT 플랫폼 서비스',
    desc:
      '귀사에 필요한 모든 IoT 환경은 UPLINK+ 에 준비되어 있습니다. 스마트 디바이스 제품의 수준을 끌어올려 내 생각대로 만들어가는 IoT 세상을 구축하시기 바랍니다.',
  },
  timeline: {
    subheading: 'UPLINK+ 서비스 흐름',
    heading: 'UPLINK+ 스마트 디바이스 업그레이드 프로세스',
    steps: [
      {
        title: '측정 항목 & 기능 정의',
        desc:
          '의뢰한 디바이스에 대해 센서의 측정 항목과 기능을 파악하여 정의하는 단계',
      },
      {
        title: '프로토콜 분석',
        desc:
          '의뢰한 디바이스에서 수집한 데이터를 UPLINK+ 에서 정확히 수신하기 위한 상호 분석 단계',
      },
      {
        title: '플랫폼 연결(IoT 모듈 사용 시 1,2단계 필요없음)',
        desc:
          '의뢰한 디바이스의 프로토콜 분석을 완료 후 로딩하여 플랫폼과 연결하는 단계',
      },
    ],
    freeTrial: 'DEMO 신청',
  },
  feature: {
    subheading: '제공 서비스',
    heading: 'UPLINK+에서 제공하는 핵심 서비스',
    items: [
      {
        id: 1,
        icon: 'flaticon-settings',
        title: '강력한 IoT 모듈',
        desc:
          'UPLINK+ 플랫폼과 바로 연결되는 와이파이 모듈로 디바이스 개발시간을 단축하고 플랫폼 개발비용을 절약합니다.',
      },
      {
        id: 2,
        icon: 'flaticon-upload',
        title: '올-인-원 서비스',
        desc:
          '기업의 스마트 디바이스를 위한 IoT 모듈, 플랫폼(웹/앱), 사용자 커스터마이징 및 유지/보수까지 올-인-원 서비스를 제공합니다.',
      },
      {
        id: 3,
        icon: 'flaticon-strategy',
        title: '커스터마이징 서비스',
        desc:
          '제품의 타입, 프로토콜, 클라이언트 유형에 따른 커스터마이징 서비스 제공',
      },
      {
        id: 4,
        icon: 'flaticon-security',
        title: '최고 레벨 보안 환경',
        desc: 'UPLINK+는 고객에게 최고 수준의 보안 환경을 제공합니다.',
      },
      {
        id: 5,
        icon: 'flaticon-export',
        title: '스마트 리포트',
        desc:
          '고객의 요청에 따라 내장 분석 툴을 통하여 매월 데이터를 자동 분석하여 제공, 별도의 분석 서비스를 이용할 필요가 없습니다.',
      },
      {
        id: 6,
        icon: 'flaticon-trophy',
        title: '편의성',
        desc:
          '고객의 쉽고 빠르게 판단할 수 있도록 최대한 간결하고 쉬운 UI/UX 구성',
      },
    ],
  },
  supportType: {
    heading:
      'UPLINK+는 다양한 형태의 하드웨어 타입 과 산업표준/오픈 프로토콜을 지원합니다.',
    desc:
      'Sensors, Machines, Smartphone, Industrial PLC 등의 하드웨어타입과 HTTPS, MQTT with TLS, Web Socket, CoAP 등의 프로토콜을 지원합니다.',
  },
  pricing: {
    subheading: 'PRICING PLAN',
    heading: 'UPLINK+ 가격정책',
    freeTrial: 'DEMO 신청',
    priceLabelRed: 'Transform IoT',
    priceLabelNormal: '내 생각대로 만들어지는 IoT 플랫폼',
    customHelpText: '* 플랫폼 및 커스터마이징 앱 구매 별도 문의',
    sharedText: '공통 사항',
    shared: [
      //'  • 플랫폼과 다이렉트로 연결되는 IoT 모듈(와이파이)',
      '  • 전용 대쉬보드 웹 제공(관리 및 분석)',
      //'  • IoT 모듈 개발문서 제공',
      '  • 전용 iOS/Android 앱 제공',
      '  • 사용자별 권한 액세스 기능 지원',
      '  • 데이터 추출 기능 지원',
      //'  • 원격 펌웨어 관리',
      '  • 원격 기기 제어',
      '  • 온라인 기술 지원',
    ],
    withModule: {
      buttonText: '연간 이용료\n(with IoT 모듈)',
      data: [
        {
          title: '스타트업',
          desc: '10개 이하의 디바이스를 연결/운영 하고자 하는 회사에 적합',
          price: '550,000원',
          priceDesc: '연간 가격',
          listItems: ['최대 연결 기기수 10EA', 'IoT 모듈 제공 10EA'],
        },
        {
          title: '스탠다드',
          desc: '30개 이하의 디바이스를 연결/운영 하고자 하는 회사에 적합',
          price: '1,480,500원',
          priceDesc: '연간 가격',
          listItems: ['최대 연결 기기수 30EA', 'IoT 모듈 제공 30EA'],
        },
        {
          title: '프로페셔널',
          desc: '50개 이하의 디바이스를 연결/운영 하고자 하는 회사에 적합',
          price: '2,200,000원',
          priceDesc: '연간 가격',
          listItems: ['최대 연결 기기수 50EA', 'IoT 모듈 제공 50EA'],
        },
      ],
    },
    onlyPlatform: {
      buttonText: '연간 이용료\n(Only Platform)',
      data: [
        {
          title: '스타트업',
          desc: '10개 이하의 디바이스를 연결/운영 하고자 하는 회사에 적합',
          price: '500,000원',
          priceDesc: '연간 가격',
          listItems: ['최대 연결 기기수 10EA'],
        },
        {
          title: '스탠다드',
          desc: '30개 이하의 디바이스를 연결/운영 하고자 하는 회사에 적합',
          price: '1,350,000원',
          priceDesc: '연간 가격',
          listItems: ['최대 연결 기기수 30EA'],
        },
        {
          title: '프로페셔널',
          desc: '50개 이하의 디바이스를 연결/운영 하고자 하는 회사에 적합',
          price: '2,000,000원',
          priceDesc: '연간 가격',
          listItems: ['최대 연결 기기수 50EA'],
        },
      ],
    },
  },
  partner: {
    heading: 'UPLINK+ 비즈니스 파트너',
    desc:
      'UPLINK+와 함께하는 비즈니스 파트너를 소개합니다. 또한 다양한 산업분야에서 성공적으로 활용중인 UPLINK+ 비즈니스 케이스를 확인해보세요.',
  },
  footer: {
    privacyLabel: '개인정보 보호정책',
    termsLabel: '이용약관',
    copyright: '2020 (c) UPLINK',
  },
  contactForm: {
    heading: '데모 계정 신청',
    company: '회사명',
    info: '회사소개',
    uname: '이름',
    passwd: '비밀번호',
    passwd2: '비밀번호 확인',
    phone: '연락처',
    email: '이메일',
    module: '컨트롤러 모듈 신청',
    remark: '요청사항',
    mcount: '수량',
    department: '부서/직급',
    applyFor: '신청 분야',
    applyForOptions: [
      '플랫폼 단독',
      'IoT 모듈 + 플랫폼',
      '소프트웨어 라이센싱',
    ],
    iotModule: 'IoT 모듈 신청 여부',
    iotModuleOptions: ['신청', '미신청'],
    address: '받으실 주소',
    helpText: '입력하신 정보를 확인 후 연락드리도록 하겠습니다',
    buttonText: '입력 완료',
    submitSuccess:
      '가입이 완료되었습니다.',
    submitFailed: '오류가 발생하였습니다. 잠시후 다시 시도해 주시요.',
    errorMessages: {
      captcha: '로봇이 아닙니다를 클릭하세요.',
      company: '회사명을 입력하세요.',
      uname: '이름을 입력하세요.',
      phone: '전화번호(회사)를 입력하세요.',
      email: '이메일 주소를 입력하세요.',
      applyFor: '신청 분야를 하나 이상 선택하세요.',
      address: '주소를 입력하세요.',
      passwd: '비밀번호를 입력하세요.',
      module: '컨트롤러 모듈 신청을 체크하세요.',
      passwd2: '비밀번호가 틀립니다.',
      mcount: '컨트롤러 모듈 최대 수량이 초과하였습니다.',
      passwdcount: '비밀번호를 6자리 이상 작성하세요.'
    },
  },
  privacy: {
    heading: 'Privacy Policy',
  },
};

export default labelsKo;
