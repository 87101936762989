import labelsKo from '../labels.ko';
import labelsEn from '../labels.en';

const labels = {
  ko: labelsKo,
  en: labelsEn,
};

class Util {
  #lang = 'ko';
  set lang(lang) {
    this.#lang = lang;
  }
  label(category, key) {
    return labels[this.#lang][category][key];
  }
}

const util = new Util();

export default util;
